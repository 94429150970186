import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getProject } from '../../apis/ProjectApi';
import { who } from '../../apis/UserApi';
import { useAppSelector } from '../../hooks';
import { fetchResource, setCurrenProject } from '../../slices/projectSlice';

import ProjectSidebar from '../../components/ProjectSidebar';

import { getElevation } from '../../utils/Map3dUtil';

import SuspendDialog from './SuspendDialog';

const DashboardContainer = styled('div')`
  display: flex;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(180deg, #3e3e3e 0%, #212121 100%);
`;

const HomePage = () => {
  const dispatch = useDispatch();
  const selectedProjectId = useAppSelector(
    (store) => store.system.selectedProjectId,
  );
  const orgMap = useAppSelector((store) => store.system.orgMap);

  const { data: project, isLoading } = useQuery(
    ['get-project', selectedProjectId],
    async () => {
      try {
        const res = await getProject(selectedProjectId as string);
        if (res) {
          const elevation = await getElevation(res.center[0], res.center[1]);
          dispatch(setCurrenProject({ ...res, elevation }));
        }
        return res;
      } catch (error) {
        if (error instanceof AxiosError) {
          throw error;
        }
      }
    },
    {
      enabled: !!selectedProjectId,
      refetchOnWindowFocus: false,
    },
  );

  useQuery(
    ['get-all-workers', selectedProjectId],
    async () => {
      if (selectedProjectId) {
        dispatch(
          // @ts-ignore
          fetchResource({
            projectId: selectedProjectId,
            resourceType: 'worker',
          }),
        );
      }
      return null;
    },
    {
      enabled: !!selectedProjectId,
      refetchOnWindowFocus: false,
      refetchInterval: 600000,
    },
  );

  const status = orgMap[project?.orgId ?? '']?.status ?? 'ACTIVE';

  const { data: owner } = useQuery(
    ['get-who', `ownerId=${project?.ownerId}`],
    async () => {
      const res = await who({ id: project?.ownerId });
      return res.data.data;
    },
    {
      enabled: !!project?.ownerId,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <DashboardContainer className="DashboardContainer">
      {!isLoading && (
        <>
          {status === 'ACTIVE' && (
            <>
              <ProjectSidebar />
              <Outlet />
            </>
          )}
          <SuspendDialog open={status === 'SUSPENDED'} owner={owner} />
        </>
      )}
    </DashboardContainer>
  );
};

export default HomePage;
