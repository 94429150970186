import React, { CSSProperties, useMemo } from 'react';
import { Avatar, styled } from '@mui/material';

import { ResourceType } from '../../../types';
import {
  Equipment,
  Pipe,
  Plant,
  Resource,
  Vehicle,
  Worker,
} from '../../../types/Resource';

import { useAppSelector } from '../../../hooks';

import envDefaultPng from '../../../assets/images/default-image/environment_default.png';
import equipmentDefaultPng from '../../../assets/images/default-image/equipment_default.png';
import pipeDefaultPng from '../../../assets/images/default-image/pipe_default.png';
import planDefaultPng from '../../../assets/images/default-image/plant_default.png';
import vehicleDefaultPng from '../../../assets/images/default-image/vehicle_default.png';
import workerDefaultPng from '../../../assets/images/default-image/worker_default.png';
import CheckboxDark from '../../Checkbox/CheckboxDark';

interface ContainerProps {
  orgColor?: CSSProperties['color'];
}

//prettier-ignore
const Container = styled('div', { shouldForwardProp: prop => prop !== 'orgColor' })<ContainerProps>`
  display: flex;
  align-items: center;
  color: white;
  padding: 12px 10px;
  transition: .3s;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  & .info {
    flex: 1;
    div {
      ${({ theme }) => ({ ...theme.typography.body2 })}
      display: flex;
      align-items: center;
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:nth-of-type(2) {
        ${({ theme }) => ({ ...theme.externalTypography.body3 })}
        color: ${({ theme }) => theme.color.secondary.$60};
        &:before {
          display: inline-block;
          content: '';
          width: 6px;
          height: 14px;
          border-radius: 3px;
          background: ${({ orgColor }) =>
            orgColor ? orgColor : 'transparent'};
          margin-right: 6px;
        }
      }
      & > span{
        margin-left: 5px;
        color: ${({theme})=> theme.color.secondary.$60};
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        max-width: 350px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

const ContainerCheckbox = styled('div')`
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;

interface ResourceItemProps {
  style?: React.CSSProperties;
  resource: Resource;
  resourceType: ResourceType;
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onChange: (resourceId: string, checked: boolean) => void;
}

const ResourceItem: React.FC<ResourceItemProps> = ({
  style,
  resource,
  resourceType,
  checked,
  disabled,
  onClick,
  onChange,
}) => {
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const org = orgMap[resource.orgId];

  const { dasIds, defaultImage } = useMemo(() => {
    let localDasIds: string[] = [];
    let localDefaultImage: string = '';

    switch (resourceType) {
      case 'worker':
        localDasIds = [...(resource as Worker).bindingDasloops];
        localDefaultImage = workerDefaultPng;
        break;
      case 'plant':
        localDasIds = [...(resource as Plant).bindingDastracks];
        localDefaultImage = planDefaultPng;
        break;
      case 'vehicle':
        localDasIds = [...(resource as Vehicle).bindingDastrackVs];
        localDefaultImage = vehicleDefaultPng;
        break;
      case 'equipment':
        localDasIds = (resource as Equipment).bindingDastemps
          .concat((resource as Equipment).bindingDaspowers)
          .concat((resource as Equipment).bindingDaslocks);
        localDefaultImage = equipmentDefaultPng;
        break;
      case 'pipe':
        localDasIds = [...(resource as Pipe).bindingDaswaters];
        localDefaultImage = pipeDefaultPng;
        break;
      case 'environment':
        localDefaultImage = envDefaultPng;
        break;
    }
    return { dasIds: localDasIds, defaultImage: localDefaultImage };
  }, [resource, resourceType]);

  return (
    <>
      <Container
        style={style}
        orgColor={org?.color ?? 'black'}
        onClick={onClick}
      >
        <ContainerCheckbox>
          <CheckboxDark
            value={resource.id}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
        </ContainerCheckbox>
        <Avatar
          variant="square"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            objectFit: 'cover',
            marginRight: '10px',
          }}
          src={resource.imageURL ?? defaultImage}
        />
        <div className="info">
          <div title={resource.name}>
            {resource.name}{' '}
            {dasIds.length > 0 && (
              <span title={`(${dasIds.join(', ')})`}>{`(${dasIds.join(
                ', ',
              )})`}</span>
            )}
          </div>
          <div title={org?.name}>{org?.name}</div>
        </div>
      </Container>
    </>
  );
};

export default ResourceItem;
