import axios from 'axios';
import { Cartographic, createWorldTerrain, Ion, sampleTerrain } from 'cesium';

import { ELEVATION_SERVER } from '../constant/constants';

type OPEN_ELEVATION = {
  latitude: number;
  longitude: number;
  elevation?: number;
  error?: string;
};

Ion.defaultAccessToken = import.meta.env.VITE_CESIUM_ACCESS_TOKEN ?? '';

const terrainProvider = createWorldTerrain();

export const getElevation = async (lon: number, lat: number) => {
  if (ELEVATION_SERVER) {
    const res = await axios.get<OPEN_ELEVATION[]>(
      `${ELEVATION_SERVER}/api/v1/lookup`,
      {
        params: {
          locations: `${lon},${lat}`,
        },
      },
    );
    if (res.data[0]) {
      if (typeof res.data[0].elevation === 'number') {
        return res.data[0].elevation;
      }
    }
    return 0;
  } else {
    const result = await sampleTerrain(terrainProvider, 13, [
      Cartographic.fromDegrees(lon, lat),
    ]);
    return Number(result[0].height.toFixed(2));
  }
};
