import { capitalize } from '@mui/material';
import {
  addDays,
  addHours,
  addMonths,
  endOfDay,
  endOfHour,
  endOfMonth,
  startOfDay,
  startOfHour,
  startOfMonth,
} from 'date-fns';

import {
  ChartData,
  DataPoint,
  DataPointBeacon,
  DeviceType,
  EquipmentWithOrg,
  IntervalType,
  PipeWithOrg,
  PlantWithOrg,
  PositionAttendance,
  VehicleWithOrg,
  WorkerWithOrg,
} from '../types';
import { DasairType, DasGasType, HazardRank } from '../types/Dasair';
import {
  MapListDasair,
  MapListDasGas,
  MapListDaslock,
  MapListDaspower,
  MapListDastemp,
  MapListDaswater,
  MapListPlant,
  MapListWorker,
} from '../types/Device';
import { ResourceNavigator } from '../types/Resource';

import { getAirQualityHazardRank } from './getHazardRanking';

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const navigatorWorker: ResourceNavigator = [
  { id: 1, name: 'group', required: false },
  { id: 2, name: 'worker-basic-information', required: true },
  { id: 3, name: 'emergency-contact', required: false },
  { id: 4, name: 'certificate', required: false },
  { id: 5, name: 'connected-device', required: false },
];

export const navigatorPlant: ResourceNavigator = [
  { id: 1, name: 'group', required: false },
  { id: 2, name: 'plant-basic-information', required: true },
  { id: 3, name: 'emergency-contact', required: false },
  { id: 4, name: 'connected-device', required: false },
];

export const navigatorTowerCrane: ResourceNavigator = [
  { id: 1, name: 'group', required: false },
  { id: 2, name: 'tower-crane-basic-information', required: true },
  { id: 3, name: 'emergency-contact', required: false },
  { id: 4, name: 'connected-device', required: false },
];

export const navigatorEquipment: ResourceNavigator = [
  { id: 1, name: 'group', required: false },
  { id: 2, name: 'equipment-basic-information', required: true },
  { id: 3, name: 'emergency-contact', required: false },
  { id: 4, name: 'connected-device', required: false },
];

export const navigatorVehicle: ResourceNavigator = [
  { id: 1, name: 'group', required: false },
  { id: 2, name: 'vehicle-basic-information', required: true },
  { id: 3, name: 'path', required: true },
  { id: 4, name: 'emergency-contact', required: false },
  { id: 5, name: 'connected-device', required: false },
];

export const navigatorPipe: ResourceNavigator = [
  { id: 1, name: 'group', required: false },
  { id: 2, name: 'pipe-basic-information', required: true },
  { id: 3, name: 'emergency-contact', required: false },
  { id: 4, name: 'connected-device', required: false },
];

export const navigatorEnvironmant: ResourceNavigator = [
  { id: 1, name: 'group', required: false },
  { id: 2, name: 'environment-basic-information', required: true },
  { id: 3, name: 'area', required: true },
  { id: 4, name: 'emergency-contact', required: false },
];

export const navigatorStructure: ResourceNavigator = [
  { id: 1, name: 'structure-basic-information', required: true },
  { id: 2, name: 'emergency-contact', required: false },
  { id: 3, name: 'connected-device', required: false },
];

export const navigatorAi: ResourceNavigator = [
  { id: 1, name: 'group', required: false },
  { id: 2, name: 'ai-basic-information', required: true },
  { id: 3, name: 'emergency-contact', required: false },
  { id: 4, name: 'connected-device', required: false },
];

export const unionDeviceListToMap = <
  T extends
    | MapListWorker
    | MapListPlant
    | MapListDaspower
    | MapListDastemp
    | MapListDasair
    | MapListDasGas
    | MapListDaswater
    | MapListDaslock,
>(
  type: DeviceType,
  ...lists: T[][]
) => {
  const map: { [id: string]: T | undefined } = {};

  lists.forEach((list) => {
    list.forEach((data) => {
      if (type === 'dasloop') {
        const device = data as MapListWorker;
        if (!map[device.dasloop.id]) {
          map[device.dasloop.id] = data;
        }
      } else if (type === 'dastrack') {
        const device = data as MapListPlant;
        if (!map[device.dastrack.id]) {
          map[device.dastrack.id] = data;
        }
      } else if (type === 'daspower') {
        const device = data as MapListDaspower;
        if (!map[device.equipment.id]) {
          map[device.equipment.id] = data;
        }
      } else if (type === 'dastemp') {
        const device = data as MapListDastemp;
        if (!map[device.equipment.id]) {
          map[device.equipment.id] = data;
        }
      } else if (type === 'dasair') {
        const device = data as MapListDasair;
        if (!map[device.dasair.id]) {
          map[device.dasair.id] = data;
        }
      } else if (type === 'dasgas') {
        const device = data as MapListDasGas;
        if (!map[device.dasGas.id]) {
          map[device.dasGas.id] = data;
        }
      } else if (type === 'daswater') {
        const device = data as MapListDaswater;
        if (!map[device.daswater.id]) {
          map[device.daswater.id] = data;
        }
      } else if (type === 'daslock') {
        const device = data as MapListDaslock;
        if (device?.equipment?.id && !map[device.equipment.id]) {
          map[device.equipment.id] = data;
        }
      }
    });
  });
  return map;
};

export const unionResourceListToMap = <
  T extends
    | WorkerWithOrg
    | PlantWithOrg
    | EquipmentWithOrg
    | VehicleWithOrg
    | PipeWithOrg,
>(
  ...lists: T[][]
) => {
  const map: { [id: string]: T | undefined } = {};
  lists.forEach((list) => {
    list.forEach((data) => {
      if (!map[data.id]) {
        map[data.id] = data;
      }
    });
  });

  return map;
};

export const getColorAttendance = (status: PositionAttendance | undefined) => {
  switch (status) {
    case 'enter':
      return '#FF4500';
    case 'exit':
      return '#FFFFFF';
    case 'offline':
      return '#262626';
    default:
      return '#262626';
  }
};

export const getDistance = (val: string | undefined) => {
  if (val) {
    const result = parseInt(val) / 100;
    if (result > 50) {
      return '> 50';
    }
    return result.toFixed(1);
  }
};

export const summaryAirQualityRank = (data: {
  [airtype: string]: DataPoint<any> | null;
}): HazardRank => {
  return Object.entries(data).reduce((prev, [airType, airData]) => {
    const rank: HazardRank | undefined =
      typeof airData?.value === 'number'
        ? getAirQualityHazardRank(
            airType.toUpperCase() as DasairType | DasGasType,
            airData.value,
          )
        : 'good';

    if (prev === 'good') {
      if (rank === 'attention' || rank === 'hazardous') {
        return rank;
      }
    } else if (prev === 'attention') {
      if (rank === 'hazardous') {
        return rank;
      }
    }

    return prev;
  }, 'good' as HazardRank);
};

export const contryCodeRegex = /^\+(886|1|852|44|61|44|81|65|60|66)/;
export const phoneRegex = /^\+(886|1|852|44|61|44|81|65|60|66)(\d*)/;

export const avgByDay = (
  data: Array<{ value: number | null; timestamp: string }>,
) => {
  const avgData: typeof data = [];
  let sum = 0;
  let count = 0;
  let currentDate: Date;
  const localData = [...data].sort((a, b) => {
    const aTime = new Date(a.timestamp).getTime();
    const bTime = new Date(b.timestamp).getTime();
    return aTime - bTime;
  });

  localData.forEach((d, i) => {
    if (i === 0) {
      currentDate = startOfDay(new Date(d.timestamp));
      sum += d.value ?? 0;
      count = 1;
    } else if (i === data.length - 1) {
      const dateMs = new Date(d.timestamp).getTime();
      if (dateMs - currentDate.getTime() >= 86400000) {
        avgData.push({
          value: sum / count,
          timestamp: currentDate.toISOString(),
        });
        avgData.push({
          value: d.value,
          timestamp: startOfDay(new Date(d.timestamp)).toISOString(),
        });
      } else {
        sum += d.value ?? 0;
        count++;
        avgData.push({
          value: sum / count,
          timestamp: currentDate.toISOString(),
        });
      }
    } else {
      const dateMs = new Date(d.timestamp).getTime();
      if (dateMs - currentDate.getTime() >= 86400000) {
        avgData.push({
          value: sum / count,
          timestamp: currentDate.toISOString(),
        });
        sum = d.value ?? 0;
        count = 1;
        currentDate = startOfDay(new Date(d.timestamp));
      } else {
        sum += d.value ?? 0;
        count++;
      }
    }
  });

  return avgData;
};

export const extentData = (
  data: Array<{ value: number | null; timestamp: string }>,
) => {
  if (data.length <= 0) {
    return [];
  }

  if (data.length < 7) {
    const newData = [...data];

    const realDataLastDate = new Date(data[data.length - 1].timestamp);
    for (let i = 0; i < 7 - data.length; i++) {
      newData.push({
        value: null,
        timestamp: addDays(realDataLastDate, i + 1).toISOString(),
      });
    }
    return newData;
  }

  return data;
};

const strengthcolorMap: Array<{ hex: number; value: number }> = [
  {
    hex: 0x000f99,
    value: 0,
  },
  {
    hex: 0x3500cc,
    value: 5,
  },
  {
    hex: 0x0040ff,
    value: 10,
  },
  {
    hex: 0x00bfff,
    value: 15,
  },
  {
    hex: 0x00bfff,
    value: 15,
  },
  {
    hex: 0x00e5ac,
    value: 20,
  },
  {
    hex: 0x00e525,
    value: 25,
  },
  {
    hex: 0xffe600,
    value: 30,
  },
  {
    hex: 0xffbf00,
    value: 35,
  },
  {
    hex: 0xff8000,
    value: 40,
  },
  {
    hex: 0xff4000,
    value: 45,
  },
  {
    hex: 0xff0000,
    value: 50,
  },
];

export const getStrengthColor = (n: number | null): number => {
  if (typeof n !== 'number') {
    return 0xffffff;
  }
  if (n < 0) return 0x000000;
  for (let i = 0; i < strengthcolorMap.length - 1; i++) {
    if (n >= strengthcolorMap[i].value && n < strengthcolorMap[i + 1].value) {
      return strengthcolorMap[i].hex;
    }
  }
  if (n >= strengthcolorMap[strengthcolorMap.length - 1].value) {
    return strengthcolorMap[strengthcolorMap.length - 1].hex;
  }
  return 0x000000;
};

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number,
) {
  let timeout: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<T>) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const alignChartData = (
  devicesInfo: Array<{
    id: string;
    name: string;
    color: React.CSSProperties['color'];
    data: ChartData[];
  }>,
) => {
  const allTimestampSet = new Set<string>();
  let allTimestamps: Date[] = [];

  devicesInfo.map((info) => {
    return info.data.map((d) => allTimestampSet.add(d.timestamp));
  });

  allTimestamps = Array.from(allTimestampSet)
    .map((t) => new Date(t))
    .sort((dateA, dateB) => dateA.getTime() - dateB.getTime());

  const devicesChartDataIndex = devicesInfo.map(() => 0);
  const alignedDevicesChartData: ChartData[][] = devicesInfo.map(() => []);

  allTimestamps.forEach((currT) => {
    devicesInfo.forEach((info, index) => {
      const deviceT = new Date(
        info.data[devicesChartDataIndex[index]].timestamp,
      );

      if (currT.getTime() === deviceT.getTime()) {
        alignedDevicesChartData[index].push(
          info.data[devicesChartDataIndex[index]],
        );
        if (devicesChartDataIndex[index] + 1 < info.data.length) {
          devicesChartDataIndex[index]++;
        }
      } else {
        alignedDevicesChartData[index].push({
          timestamp: currT.toISOString(),
          value: null,
        });
      }
    });
  });

  return devicesInfo.map((info, index) => {
    return {
      ...info,
      data: alignedDevicesChartData[index],
    };
  });
};

export const isOutOfDate = (target: Date, now: Date, days: number) => {
  const comparedDate = addDays(now, -1 * days);
  return comparedDate.getTime() >= target.getTime();
};

export const toProductionName = (text: string) => {
  return (
    text.charAt(0).toUpperCase() +
    text.substring(1, 3) +
    text.charAt(3).toUpperCase() +
    text.substring(4)
  );
};

export const compareLatestBeacons = (
  aBeacons: DataPoint<DataPointBeacon[]> | undefined,
  bBeacons: DataPoint<DataPointBeacon[]> | undefined,
): DataPoint<DataPointBeacon[]> => {
  if (!aBeacons?.timestamp && bBeacons?.timestamp) {
    return bBeacons;
  } else if (aBeacons?.timestamp && !bBeacons?.timestamp) {
    return aBeacons;
  } else if (aBeacons?.timestamp && bBeacons?.timestamp) {
    const aTime = new Date(aBeacons.timestamp).getTime();
    const bTime = new Date(bBeacons.timestamp).getTime();
    if (aTime >= bTime) return aBeacons;
    return bBeacons;
  }

  return {
    value: [],
    stale: true,
    timestamp: null,
  };
};

export const getRangeDateInterval = (interval: IntervalType) => {
  const nowDate = new Date();
  let fromDate: string = '';
  let toDate: string = '';
  switch (interval) {
    case 'day':
      fromDate = startOfDay(addDays(nowDate, -29)).toISOString();
      toDate = endOfDay(nowDate).toISOString();
      break;
    case 'month':
      fromDate = startOfMonth(addMonths(nowDate, -11)).toISOString();
      toDate = endOfMonth(nowDate).toISOString();
      break;
    default:
      fromDate = startOfHour(addHours(nowDate, -23)).toISOString();
      toDate = endOfHour(nowDate).toISOString();
      break;
  }
  return { fromDate, toDate };
};

export const getDeviceTypeByDasId = (dasId: string): DeviceType | undefined => {
  const dasloopPattern = /^LL|^LN|^LF|^LM|^LV/;
  const dasCTagPattern = /^TU/;

  if (dasloopPattern.test(dasId)) {
    return 'dasloop';
  } else if (dasCTagPattern.test(dasId)) {
    return 'das_collision_tag';
  }
};

export const transFormalDeviceTitle = (deviceName: string) => {
  let name = '';
  for (let i = 0; i < deviceName.length; i++) {
    if (i === 0 || i === 3) {
      name += capitalize(deviceName[i]);
    } else {
      name += deviceName[i];
    }
  }
  return name;
};
