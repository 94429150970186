import { FeatureType, FloorPlanRectangle } from '../types';

import AustraliaSvg from '../assets/images/svg/flags/Australia.svg';
import CanadaSvg from '../assets/images/svg/flags/Canada.svg';
import HongKongSvg from '../assets/images/svg/flags/HongKong.svg';
import JapanSvg from '../assets/images/svg/flags/Japan.svg';
import MalaysiaSvg from '../assets/images/svg/flags/Malaysia.svg';
import SingaporeSvg from '../assets/images/svg/flags/Singapore.svg';
import TaiwanSvg from '../assets/images/svg/flags/Taiwan.svg';
import ThailandSvg from '../assets/images/svg/flags/Thailand.svg';
import UnitedKingdomSvg from '../assets/images/svg/flags/UnitedKingdom.svg';
import UnitedStateOfAmericaSvg from '../assets/images/svg/flags/UnitedStateOfAmerica.svg';

interface PhoneCode {
  code: string;
  name: string;
  src: string;
}

export const PHONE_LIST: PhoneCode[] = [
  {
    code: '886',
    name: 'Taiwan',
    src: TaiwanSvg,
  },
  {
    code: '852',
    name: 'Hong Kong',
    src: HongKongSvg,
  },
  {
    code: '44',
    name: 'United Kingdom',
    src: UnitedKingdomSvg,
  },
  {
    code: '1',
    name: 'United State of America',
    src: UnitedStateOfAmericaSvg,
  },
  {
    code: '1',
    name: 'Canada',
    src: CanadaSvg,
  },
  {
    code: '61',
    name: 'Australia',
    src: AustraliaSvg,
  },
  {
    code: '81',
    name: 'Japan',
    src: JapanSvg,
  },
  {
    code: '65',
    name: 'Singapore',
    src: SingaporeSvg,
  },
  {
    code: '60',
    name: 'Malaysia',
    src: MalaysiaSvg,
  },
  {
    code: '66',
    name: 'Thailand',
    src: ThailandSvg,
  },
];

interface BirthDayList {
  id: string;
  value: string;
  name: string;
}
export const BIRTHDAY_LIST: BirthDayList[] = [];
for (let i = 0; i <= 100; i += 1) {
  const date = new Date();
  const updateYear = date.setFullYear(date.getFullYear() - i);
  const year = new Date(updateYear).getFullYear().toString();
  BIRTHDAY_LIST.push({
    id: year,
    value: year,
    name: year,
  });
}

export const TIME_LOADING = 1000;
export const TIME_FINISH_LOADING = 500;
export const TIME_REFETCH = 500;
export const TIME_UPDATE_BITRATE = 3000;

export const BASIC_RESCAN_TIME = parseInt(
  import.meta.env.VITE_BASIC_RESCAN_TIME ?? '150000',
  10,
);

export const DASHBOARD_RESCAN_TIME = parseInt(
  import.meta.env.VITE_DASHBOARD_RESCAN_TIME ?? '150000',
  10,
);

export const DASLOOP_DASHBOARD_RESCAN_TIME = parseInt(
  import.meta.env.VITE_DASLOOP_DASHBOARD_RESCAN_TIME ?? '600000',
  10,
);

export const DASHBOARD_STOP_STATUS_TIME = parseInt(
  import.meta.env.VITE_DASHBOARD_STOP_STATUS_TIME ?? '300000',
  10,
);

export const DASHBOARD_ALERTS_REFRESH_TIME = parseInt(
  import.meta.env.VITE_DASHBOARD_ALERTS_REFRESH_TIME ?? '600000',
  10,
);

export const DASPOWER_DASHBOARD_RESCAN_TIME = parseInt(
  import.meta.env.VITE_DASPOWER_DASHBOARD_RESCAN_TIME ?? '300000',
  10,
);

export const DASPOWER_DASHBOARD_DEVICE_TIMEOUT = parseInt(
  import.meta.env.VITE_DASPOWER_DASHBOARD_DEVICE_TIMEOUT ?? '300000',
);

export const DASWATER_DASHBOARD_RESCAN_TIME = parseInt(
  import.meta.env.VITE_DASWATER_DASHBOARD_RESCAN_TIME ?? '300000',
  10,
);

export const DASWATER_DASHBOARD_DEVICE_TIMEOUT = parseInt(
  import.meta.env.VITE_DASWATER_DASHBOARD_DEVICE_TIMEOUT ?? '1200000',
);

export const DASAIR_DASHBOARD_RESCAN_TIME = parseInt(
  import.meta.env.VITE_DASAIR_DASHBOARD_RESCAN_TIME ?? '300000',
  10,
);

export const DASAIR_DASHBOARD_DEVICE_TIMEOUT = parseInt(
  import.meta.env.VITE_DASAIR_DASHBOARD_DEVICE_TIMEOUT ?? '150000',
  10,
);

export const DASGAS_DASHBOARD_RESCAN_TIME = parseInt(
  import.meta.env.VITE_DASGAS_DASHBOARD_RESCAN_TIME ?? '60000',
  10,
);

export const ATTENDANCE_STOP_STATUS_TIME = parseInt(
  import.meta.env.VITE_ATTENDANCE_DEVICE_TIMEOUT ?? '60000',
  10,
);

export const LOG_REFRESH_TIME = parseInt(
  import.meta.env.VITE_LOG_REFRESH_TIME ?? '300000',
  10,
);

export const ANNOUNCEMENT_RESCAN_TIME = parseInt(
  import.meta.env.VITE_ANNOUNCEMENT_RESCAN_TIME ?? '300000',
  10,
);

export const ANNOUNCEMENT_TASK_RESCAN_TIME = parseInt(
  import.meta.env.VITE_ANNOUNCEMENT_TASK_RESCAN_TIME ?? '60000',
  10,
);

export const CONFINED_SPACE_RESCAN_TIME = parseInt(
  import.meta.env.VITE_CONFINED_SPACE_RESCAN_TIME ?? '60000',
  10,
);

export const CONFINED_SPACE_CAROUSEL_TIME = parseInt(
  import.meta.env.VITE_CONFINED_SPACE_CAROUSEL_TIME ?? '5000',
  10,
);

export const MAX_SAVEABLE_AUDIO_TIME = parseInt(
  import.meta.env.VITE_MAX_SAVEABLE_AUDIO_TIME ?? '40',
  10,
);

export const LIVE_CALL_RESCAN_TIME = parseInt(
  import.meta.env.VITE_LIVE_CALL_RESCAN_TIME ?? '10000',
  10,
);

export const ALERT_EXPIRED_DAYS = parseInt(
  import.meta.env.VITE_ALERT_EXPIRED_DAYS ?? '90',
  10,
);

export const INIT_RECT: FloorPlanRectangle = [
  [0, 0],
  [0, 0],
  [0, 0],
  [0, 0],
];

export const INIT_COORD_FLOOR_PLAN: [
  [number, number],
  [number, number],
  [number, number],
  [number, number],
] = [
  [0.00001, -0.00001],
  [0.00001, 0.00001],
  [-0.00001, 0.00001],
  [-0.00001, -0.00001],
];

export const ONE_DAY_TO_MILLISECONDS = 24 * 60 * 60 * 1000;
export const ONE_HOUR_TO_MILLISECONDS = 60 * 60 * 1000;

export const LIST_OF_FEATURE: Array<FeatureType> = [
  'dasloop',
  'dastrack',
  'dastrack_v',
  'dasbeacon',
  'daspower',
  'dastemp',
  'dasair',
  'dasmain',
  'das_aoa_tag',
  'daswatch',
  'cctv',
  '3d_plus',
  'dasgas',
  'daswater',
];

export const ON_PREMISE_MAP_TILE_SERVER: string = import.meta.env
  .VITE_ON_PREMISE_MAP_TILE_SERVER;

export const MAP_TILER_API_KEY: string = import.meta.env.VITE_MAP_TILER_API_KEY;

export const ON_PREMISE_MODE: boolean =
  import.meta.env.VITE_ON_PREMISE_MODE === 'true';

export const MAX_TOTAL_AUDIO_SIZE = 2000000;

export const ELEVATION_SERVER: string = import.meta.env.VITE_ELEVATION_SERVER;
