import { DasConcreteAlert } from '../types/Alert';

import CH2OSvgIcon from '../assets/SvgIcon/air/CH2OSvgIcon';
import CH4SvgIcon from '../assets/SvgIcon/air/CH4SvgIcon';
import CO2SvgIcon from '../assets/SvgIcon/air/CO2SvgIcon';
import COSvgIcon from '../assets/SvgIcon/air/COSvgIcon';
import H2SSvgIcon from '../assets/SvgIcon/air/H2SSvgIcon';
import NH3SvgIcon from '../assets/SvgIcon/air/NH3SvgIcon';
import O2SvgIcon from '../assets/SvgIcon/air/O2SvgIcon';
import PM10SvgIcon from '../assets/SvgIcon/air/PM10SvgIcon';
import PM25SvgIcon from '../assets/SvgIcon/air/PM25SvgIcon';
import VOCSvgIcon from '../assets/SvgIcon/air/VOCSvgIcon';
import AiDefaultAlertSvgIcon from '../assets/SvgIcon/alert/AiDefaultAlertSvgIcon';
import AiPpeHelAlertSvgIcon from '../assets/SvgIcon/alert/AiPpeHelAlertSvgIcon';
import AiPpeRefAlertSvgIcon from '../assets/SvgIcon/alert/AiPpeRefAlertSvgIcon';
import AreaAlertSvgIcon from '../assets/SvgIcon/alert/AreaAlertSvgIcon';
import DasConcreteAlertSvgIcon from '../assets/SvgIcon/alert/DasConcreteAlertSvgIcon';
import DasgasAlertSvgIcon from '../assets/SvgIcon/alert/DasgasAlertSvgIcon';
import DaslockAlertSvgIcon from '../assets/SvgIcon/alert/DaslockAlertSvgIcon';
import DaspowerAlertSvgIcon from '../assets/SvgIcon/alert/DaspowerAlertSvgIcon';
import DastempAlertSvgIcon from '../assets/SvgIcon/alert/DastempAlertSvgIcon';
import DaswaterLSvgIcon from '../assets/SvgIcon/alert/DaswaterLSvgIcon';
import FreeFallSvgIcon from '../assets/SvgIcon/alert/FreeFallSvgIcon';
import HeartRateSvgIcon from '../assets/SvgIcon/alert/HeartRateSvgIcon';
import LowBatterySvgIcon from '../assets/SvgIcon/alert/LowBatterySvgIcon';
import SOSSvgIcon from '../assets/SvgIcon/alert/SOSSvgIcon';
import StandStillSvgIcon from '../assets/SvgIcon/alert/StandStillSvgIcon';
import TemperatureSvgIcon from '../assets/SvgIcon/alert/TemperatureSvgIcon';
import UwbAlertSvgIcon from '../assets/SvgIcon/alert/UwbAlertSvgIcon';
import AssetSvgIcon from '../assets/SvgIcon/AssetSvgIcon';
import DasloopIconSvg from '../assets/SvgIcon/DasloopIconSvg';
import EmptyIconSvg from '../assets/SvgIcon/EmptyIconSvg';
import EnterSvgIcon from '../assets/SvgIcon/EnterSvgIcon';
import LeaveSvgIcon from '../assets/SvgIcon/LeaveSvgIcon';

export enum AlertType {
  UWB_EXIT = 'uwbExit',
  UWB_ENTER = 'uwbEnter',
  STANDSTILL = 'standstill',
  PANIC_BUTTON = 'panicButton',
  // HEAT_STRESS = 'heatStress',
  HEART_RATE_TOO_LOW = 'heartRateTooLow',
  HEART_RATE_TOO_HIGH = 'heartRateTooHigh',
  FREE_FALL = 'freeFall',
  CAP_OFF = 'capOff',
  REGULAR_SIGNAL_MISSING = 'RegularSignalMissing',
  BODY_TEMPERATURE_TOO_LOW = 'bodyTemperatureTooLow',
  BODY_TEMPERATURE_TOO_HIGH = 'bodyTemperatureTooHigh',
  DASPOWER_ALERT = 'daspowerAlert',
  AREA_ALERT = 'areaAlert',
  DASTEMP_ALERT = 'dastempAlert',
  DASAIR_O2_ALERT = 'dasair-O2-alert',
  DASAIR_CO_ALERT = 'dasair-CO-alert',
  DASAIR_CH2O_ALERT = 'dasair-CH2O-alert',
  DASAIR_NH3_ALERT = 'dasair-NH3-alert',
  DASAIR_PM2_5_ALERT = 'dasair-PM2.5-alert',
  DASAIR_PM10_ALERT = 'dasair-PM10-alert',
  DASAIR_CO2_ALERT = 'dasair-CO2-alert',
  DASAIR_H2S_ALERT = 'dasair-H2S-alert',
  DASAIR_CH4_ALERT = 'dasair-CH4-alert',
  DASAIR_VOC_ALERT = 'dasair-VOC-alert',
  DASAIR_HUMIDITY_ALERT = 'dasair-HUMIDITY-alert',
  DASAIR_TEMPERATURE_ALERT = 'dasair-TEMPERTAURE-alert',
  DASCART_ALERT = 'DASCART_ALERT',
  DASTRACK_V_ENTER_ALERT = 'dastrack-v-enter-alert',
  DASTRACK_V_LEAVE_ALERT = 'dastrack-v-leave-alert',
  DASWATER_L_DISTANCE = 'daswater-L-distance',
  DASWATER_L_LOWBATTERYALARM = 'daswater-L-battery',
  DASWATER_L_TILT = 'daswater-L-isTilt',
  DASWATER_L = 'daswater-L',
  DASGAS_O2_ALERT = 'GasO2ConcentrationTooHigh',
  DASGAS_O2_TOO_LOW_ALERT = 'GasO2ConcentrationTooLow',
  DASGAS_HC_ALERT = 'GasHCConcentrationTooHigh',
  DASGAS_H2S_ALERT = 'GasH2SConcentrationTooHigh',
  DASGAS_CO2_ALERT = 'GasCO2ConcentrationTooHigh',
  DASGAS_CO_ALERT = 'GasCOConcentrationTooHigh',
  DASGAS_CH4_ALERT = 'GasCH4ConcentrationTooHigh',
  DASGAS_SO2_ALERT = 'GasSO2ConcentrationTooHigh',
  DASGAS_MAN_DOWN = 'GAS_MAN_DOWN',
  DASGAS_PANIC = 'GAS_PANIC',
  DASLOCK_LOCKROPECUTALARM = 'LockRopeCutAlarm',
  DASLOCK_VIBRATIONALARM = 'VibrationAlarm',
  DASLOCK_SWIPEILLEGALRFIDALARM = 'SwipeIllegalRFIDAlarm',
  DASLOCK_LOWBATTERYALARM = 'LowBatteryAlarm',
  DASLOCK_BACKCOVEROPENEDALARM = 'BackCoverOpenedAlarm',
  DASLOCK_MOTORSTUCKALARM = 'MotorStuckAlarm',
  DASCONCRETE_TILT = 'tiltAngle',
  ASSET_MAINTENANCE_EXPIRED = 'maintainAtExpired',
  ASSET_VALIDATE_EXPIRED = 'validateAtExpired',
  ASSET_SCRAPPAGE = 'scrappage',
  ASSET_REPAIRING = 'repairing',
  AI_PPE_HEL = 'PPE_HEL',
  AI_PPE_REF = 'PPE_REF',
  AI_PPE_DHS = 'PPE_DHS',
  AI_PPE_BUK = 'PPE_BUK',
  AI_PPE_TOKEN_EXPIRED = 'PPE_TOKEN_EXPIRED',
}

export const getDasConcreteAlertType = (alert: DasConcreteAlert) => {
  let dasairAlertType: AlertType = AlertType.DASCONCRETE_TILT;
  switch (alert.field) {
    case 'tiltAngle':
      dasairAlertType = AlertType.DASCONCRETE_TILT;
      break;
    default:
      dasairAlertType = AlertType.DASCONCRETE_TILT;
      break;
  }
  return dasairAlertType;
};

const getColorAlert = (disabled?: boolean, isNotic?: boolean): string => {
  if (disabled && isNotic) {
    return '#FFFFFF';
  } else if (disabled) {
    return '#A1A1A8';
  }
  return '#FF6B00';
};

const getAlertIcon = (
  type: AlertType,
  disabled?: boolean,
  isNotic?: boolean,
  style?: React.CSSProperties,
) => {
  const localStyle: React.CSSProperties = {
    color: getColorAlert(disabled, isNotic),
    ...style,
  };

  switch (type) {
    case AlertType.CAP_OFF:
    case AlertType.REGULAR_SIGNAL_MISSING:
      return <DasloopIconSvg className="alert-icon" style={localStyle} />;
    case AlertType.UWB_ENTER:
    case AlertType.UWB_EXIT:
      return <UwbAlertSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.BODY_TEMPERATURE_TOO_HIGH:
    case AlertType.BODY_TEMPERATURE_TOO_LOW:
      return <TemperatureSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.HEART_RATE_TOO_HIGH:
    case AlertType.HEART_RATE_TOO_LOW:
      return <HeartRateSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.FREE_FALL:
      return <FreeFallSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.PANIC_BUTTON:
      return <SOSSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.STANDSTILL:
      return <StandStillSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASPOWER_ALERT:
      return <DaspowerAlertSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.AREA_ALERT:
      return <AreaAlertSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASTEMP_ALERT:
      return <DastempAlertSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASTRACK_V_LEAVE_ALERT:
      return <LeaveSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASTRACK_V_ENTER_ALERT:
      return <EnterSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_O2_ALERT:
      return <O2SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_CO_ALERT:
      return <COSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_CH2O_ALERT:
      return <CH2OSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_NH3_ALERT:
      return <NH3SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_PM2_5_ALERT:
      return <PM25SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_PM10_ALERT:
      return <PM10SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_CO2_ALERT:
      return <CO2SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_H2S_ALERT:
      return <H2SSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_CH4_ALERT:
      return <CH4SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASAIR_VOC_ALERT:
      return <VOCSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASWATER_L_DISTANCE:
    case AlertType.DASWATER_L_TILT:
    case AlertType.DASWATER_L:
      return <DaswaterLSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASGAS_O2_ALERT:
      return <O2SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASGAS_CO2_ALERT:
    case AlertType.DASGAS_O2_TOO_LOW_ALERT:
      return <CO2SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASGAS_H2S_ALERT:
      return <H2SSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASGAS_CH4_ALERT:
      return <CH4SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASGAS_CO_ALERT:
      return <COSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASGAS_HC_ALERT:
      return <CH4SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASGAS_SO2_ALERT:
      return <CH4SvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASGAS_MAN_DOWN:
      return <DasgasAlertSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASGAS_PANIC:
      return <DasgasAlertSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASLOCK_LOCKROPECUTALARM:
    case AlertType.DASLOCK_VIBRATIONALARM:
    case AlertType.DASLOCK_SWIPEILLEGALRFIDALARM:
    case AlertType.DASLOCK_BACKCOVEROPENEDALARM:
    case AlertType.DASLOCK_MOTORSTUCKALARM:
      return <DaslockAlertSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASLOCK_LOWBATTERYALARM:
    case AlertType.DASWATER_L_LOWBATTERYALARM:
      return <LowBatterySvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.DASCONCRETE_TILT:
      return (
        <DasConcreteAlertSvgIcon className="alert-icon" style={localStyle} />
      );
    case AlertType.ASSET_MAINTENANCE_EXPIRED:
    case AlertType.ASSET_REPAIRING:
    case AlertType.ASSET_SCRAPPAGE:
    case AlertType.ASSET_VALIDATE_EXPIRED:
      return <AssetSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.AI_PPE_HEL:
      return <AiPpeHelAlertSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.AI_PPE_REF:
      return <AiPpeRefAlertSvgIcon className="alert-icon" style={localStyle} />;
    case AlertType.AI_PPE_TOKEN_EXPIRED:
    case AlertType.AI_PPE_BUK:
    case AlertType.AI_PPE_DHS:
      return (
        <AiDefaultAlertSvgIcon className="alert-icon" style={localStyle} />
      );
    default:
      return <EmptyIconSvg className="alert-icon" style={localStyle} />;
  }
};

export default getAlertIcon;
