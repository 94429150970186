import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { styled, Typography } from '@mui/material';

import {
  Coordinates,
  DasIdWillAssign,
  DeviceType,
  InputValidationError,
  ResourceType,
} from '../../../types';
import {
  AssignDeviceItemData,
  Dascas,
  DasConcrete,
  DasCTag,
  Dasloop,
  Device,
} from '../../../types/Device';
import { Plant, Structure } from '../../../types/Resource';

import { getProjectPlants } from '../../../apis/DastrackApi';
import { getProjectOfResources } from '../../../apis/ResourceApi';
import { useAppSelector } from '../../../hooks';
import useAssignedTargetMap from '../../../hooks/useAssignedTargetMap';
import useDebounce from '../../../hooks/useDebounce';

import DeleteSvgIcon from '../../../assets/SvgIcon/DeleteSvgIcon';
import PinSvgIcon from '../../../assets/SvgIcon/PinSvgIcon';
import {
  getDeviceTypeByDasId,
  transFormalDeviceTitle,
} from '../../../utils/common';
import { handleTextDeviceType } from '../../../utils/getTextDevice';
import {
  isLatitude,
  isLongitude,
  splitCoord,
} from '../../../utils/separateLongLat';
import DialogSetPinLocation3D from '../../DialogPinLocation/DialogSetPinLocation3D';
import SelectBindToDevice from '../../SelectBindToDevice';
import InputField from '../../TextField/InputField';

const Container = styled('div')`
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 4px;
`;

const Content = styled('div')`
  border-radius: 4px;
  padding: 10px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  height: 32px;
  color: white;
  padding-left: 10px;
`;

const ContainerDelete = styled('div')`
  height: 32px;
  width: 32px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

const TitleDevice = styled('div')(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

interface ConnectedDeviceProps {
  progress?: number;
  isLoading?: boolean;
  devices: Device[];
  index: number;
  connectedDevice?: DasIdWillAssign;
  onChange: (index: number, datas: DasIdWillAssign | undefined) => void;
  onDeleteCard: (index: number) => void;
  type: ResourceType;
  location?: Coordinates | null;
}

const ConnectedDeviceCard: React.FC<ConnectedDeviceProps> = ({
  progress = 100,
  isLoading = false,
  devices,
  index,
  connectedDevice,
  onChange,
  onDeleteCard,
  type,
  location,
}) => {
  const { t } = useTranslation('project-setting');
  const { projectId } = useParams();
  const project = useAppSelector((store) => store.projects.currentProject);
  const workerMap = useAppSelector(
    (store) => store.projects.resource.workerMap,
  );

  const [dasIDError, setDasIDError] = useState<InputValidationError>({
    isError: false,
    message: '',
  });

  const [localLongLat, setLocalLongLat] = useState(
    connectedDevice?.location
      ? [connectedDevice?.location?.lon, connectedDevice?.location.lat].join(
          ', ',
        )
      : '0,0',
  );
  const queryLatLong = useDebounce(localLongLat, 1000);
  const [pinLocation, setPinLocation] = useState<[number, number]>([
    connectedDevice?.location?.lon ?? 0,
    connectedDevice?.location?.lat ?? 0,
  ]);
  const [altitude, setAltitude] = useState<number>(
    connectedDevice?.location?.alt ?? 0,
  );

  const [openPinLocation, setOpenPinLocation] = useState(false);

  const { data: plantMap } = useAssignedTargetMap<Plant>(
    ['plant-map', projectId],
    projectId,
    getProjectPlants,
    {
      enabled: !!projectId,
      refetchInterval: parseInt(
        import.meta.env.VITE_BASIC_RESCAN_TIME ?? '150000',
      ),
      refetchOnWindowFocus: false,
    },
  );

  const getProjectStructure = async (args: {
    projectId: string;
    params?: any;
    resourceType?: ResourceType;
  }) => {
    return getProjectOfResources<Structure>({
      projectId: args.projectId,
      resourceType: 'field',
      params: args.params,
    });
  };

  const { data: structureMap } = useAssignedTargetMap<Structure>(
    ['structure-map', projectId],
    projectId,
    getProjectStructure,
    {
      enabled: !!projectId,
      refetchInterval: parseInt(
        import.meta.env.VITE_BASIC_RESCAN_TIME ?? '150000',
      ),
      refetchOnWindowFocus: false,
    },
  );

  const deviceList = useMemo(() => {
    if (devices.length !== 0) {
      return devices.map<AssignDeviceItemData>((device) => {
        let name: string = device.dasId;
        let bindTo;
        let isAssigned: boolean = false;
        let batteryLevel: number = 0;
        let deviceType: DeviceType | undefined;
        let resourceId: string;
        switch (type) {
          case 'worker':
            resourceId = (device as Dasloop | DasCTag)?.workerId ?? '';
            bindTo = workerMap?.[resourceId]?.name;
            isAssigned = !!resourceId;
            batteryLevel = device?.shadow?.dataPoint?.batteryLevel?.value ?? 0;
            deviceType = getDeviceTypeByDasId(device.dasId);
            break;
          case 'crane':
            resourceId = (device as Dascas)?.plantId ?? '';
            bindTo = plantMap?.[resourceId]?.name;
            isAssigned = !!resourceId;
            deviceType = 'dascas';
            break;
          case 'structure':
            resourceId = (device as DasConcrete)?.resourceId ?? '';
            bindTo = structureMap?.[resourceId]?.name;
            isAssigned = !!resourceId;
            deviceType = 'dasconcrete';
            break;
          default:
            break;
        }

        if (/^TUCA/.test(device.dasId)) {
          name = `${device.dasId}(${(device as DasCTag).collisionId})`;
        }

        return {
          id: device.id,
          dasId: device.dasId,
          name,
          batteryLevel,
          bindTo,
          isAssigned,
          deviceType,
        };
      });
    }
    return [];
  }, [devices, workerMap, plantMap, type]);

  let deviceType: DeviceType | undefined;

  switch (type) {
    case 'worker':
      deviceType = getDeviceTypeByDasId(connectedDevice?.name ?? '');

      break;
    case 'crane':
      deviceType = 'dascas';
      break;
    case 'structure':
      deviceType = 'dasconcrete';
      break;
  }

  const handleOnChange = (
    propertyObjs: Array<{ propertyName: keyof DasIdWillAssign; value: any }>,
  ) => {
    const currentValue = {
      ...connectedDevice,
    };
    propertyObjs.forEach((obj) => {
      // @ts-ignore
      currentValue[obj.propertyName] = obj.value;
    });

    onChange(index, {
      id: currentValue.id ?? '',
      batteryLevel: currentValue.batteryLevel ?? 0,
      name: currentValue.name ?? '',
      dasId: currentValue.dasId as string,
      location: currentValue.location,
      deviceType: currentValue.deviceType,
    });
  };

  useEffect(() => {
    if (queryLatLong) {
      const { lon, lat } = splitCoord(queryLatLong);
      if (isLongitude(lon) && isLatitude(lat)) {
        setPinLocation([lon, lat]);
      }
    }
  }, [queryLatLong]);

  useEffect(() => {
    if (isLongitude(pinLocation[0]) && isLatitude(pinLocation[1])) {
      setLocalLongLat(pinLocation.join(', '));
    }
  }, [pinLocation]);

  useEffect(() => {
    if (
      location &&
      location.lat &&
      location.lon &&
      pinLocation.every((val) => val === 0)
    ) {
      setPinLocation([location.lon, location.lat]);
    } else if (project && pinLocation.every((val) => val === 0)) {
      setPinLocation(project.center);
    }
  }, [pinLocation, project, location]);

  useEffect(() => {
    if (
      connectedDevice &&
      pinLocation[0] !== 0 &&
      pinLocation[1] !== 0 &&
      (connectedDevice?.location?.lat !== pinLocation[1] ||
        connectedDevice?.location?.lon !== pinLocation[0])
    ) {
      handleOnChange([
        {
          propertyName: 'location',
          value: {
            ...connectedDevice?.location,
            lon: pinLocation[0],
            lat: pinLocation[1],
          },
        },
      ]);
    }
  }, [connectedDevice, pinLocation]);

  useEffect(() => {
    if (connectedDevice && connectedDevice.location?.alt !== altitude) {
      handleOnChange([
        {
          propertyName: 'location',
          value: {
            ...connectedDevice?.location,
            alt: altitude,
          },
        },
      ]);
    }
  }, [altitude, connectedDevice]);

  return (
    <Container>
      <Header>
        <Typography variant="body2">
          {`${t(
            'page.plant-management.dialog.create-plant.connected-device.title',
          )} ${index + 1}`}
        </Typography>
        <ContainerDelete
          onClick={() => {
            onDeleteCard(index);
          }}
        >
          <DeleteSvgIcon />
        </ContainerDelete>
      </Header>
      <Content>
        <TitleDevice>
          {handleTextDeviceType(deviceType)}
          <SpanRequire>*</SpanRequire>
        </TitleDevice>
        <SelectBindToDevice
          progress={progress}
          isLoading={isLoading}
          data={deviceList}
          placeholder={t(
            'page.worker-management.dialog.create-worker.connected-device.choose-device',
            { deviceType: transFormalDeviceTitle(deviceType ?? '') },
          )}
          popperPlaceholder="Das ID"
          onSelect={(d) => {
            handleOnChange([
              {
                propertyName: 'dasId',
                value: d?.dasId,
              },
              { propertyName: 'batteryLevel', value: d?.batteryLevel },
              { propertyName: 'id', value: d?.id },
              { propertyName: 'name', value: d?.name },
              { propertyName: 'deviceType', value: d?.deviceType },
            ]);
          }}
          onBlur={() => {
            if (!connectedDevice) {
              setDasIDError({
                isError: true,
                message: t(
                  'page.worker-management.dialog.create-worker.connected-device.select-device',
                ),
              });
            } else {
              setDasIDError({
                isError: false,
                message: '',
              });
            }
          }}
          selectedId={connectedDevice?.id}
          error={dasIDError}
          type={deviceType}
        />
        {type === 'structure' && (
          <>
            <InputField
              title={t('latlong')}
              name={t('latlong')}
              value={localLongLat}
              isRequired
              onChange={(e) => setLocalLongLat(e.target.value)}
              endAdornment={
                <PinSvgIcon
                  sx={{
                    width: '32px',
                    height: '32px',
                    cursor: 'pointer',
                    ':hover': {
                      background: 'rgba(0, 0, 0, 0.3)',
                      borderRadius: '4px',
                    },
                  }}
                  color="inherit"
                  data-cy="input-location-aoa-edit"
                  onClick={() => {
                    setOpenPinLocation(true);
                  }}
                />
              }
            />
            <InputField
              title={t('altitude')}
              name={t('altitude')}
              value={
                connectedDevice?.location?.alt
                  ? connectedDevice.location.alt
                  : ''
              }
              isRequired
              onChange={(e) => {
                setAltitude(Number(e.currentTarget.value));
              }}
              type="number"
            />
          </>
        )}

        {openPinLocation && (
          <DialogSetPinLocation3D
            onClose={() => setOpenPinLocation(false)}
            open={openPinLocation}
            mode="dasconcrete"
            pinLocation={pinLocation}
            setPinLocation={setPinLocation}
            altitude={altitude}
            setAltitude={setAltitude}
          />
        )}
      </Content>
    </Container>
  );
};

export default ConnectedDeviceCard;
