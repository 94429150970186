import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton, styled, Typography } from '@mui/material';

import { Coordinates, DasIdWillAssign, ResourceType } from '../../../types';
import { Dascas, Device } from '../../../types/Device';

import { useIdDascasMap } from '../../../hooks/devices/useIdDascasMap';
import { useProjectDasConcreteMap } from '../../../hooks/devices/useProjectDasconcreteMap';
import { useProjectDasCTagMapWithPages } from '../../../hooks/devices/useProjectDasCTagMapWithPages';
import { useProjectDasLoopMapWithPages } from '../../../hooks/devices/useProjectDasLoopMapWithPages';

import AddSvgIcon from '../../../assets/SvgIcon/AddSvgIcon';

import ConnectedDeviceCard from './ConnectedDeviceCard';

const Container = styled('div')`
  height: calc(100% - 80px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
`;

const ContentTitle = styled(Typography)`
  color: white;
  margin-bottom: 8px;
`;

const Content = styled('div')`
  border-radius: 4px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  margin-top: 10px;
`;

const Button = styled(MuiButton)`
  text-transform: none;
  height: 40px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: start;
  align-items: center;
  color: white;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

interface ConnectedDeviceContentProps {
  projectId: string | undefined;
  resourceType: ResourceType;
  data: Array<DasIdWillAssign>;
  handleDasIDWillAssign: (dasIds: Array<DasIdWillAssign>) => void;
  location?: Coordinates | null;
}

const INIT_WORKER_CARD: DasIdWillAssign = {
  id: '',
  dasId: '',
  name: '',
  batteryLevel: 0,
  location: undefined,
  deviceType: undefined,
};

const ConnectedDeviceContent: React.FC<ConnectedDeviceContentProps> = ({
  projectId,
  resourceType,
  data,
  handleDasIDWillAssign,
  location,
}) => {
  const { t } = useTranslation('project-setting');
  const [progress, setProgress] = useState(0);
  const {
    data: dasloopMap,
    isFetching: isFetchingDasLoops,
    hasNextPage,
    fetchNextPage,
  } = useProjectDasLoopMapWithPages({
    projectId,
    queryKey: ['get-project-of-dasloops', projectId],
    queryOptions: {
      enabled: resourceType === 'worker',
    },
    onProgress: (localProgress) => {
      setProgress(localProgress);
    },
  });

  const {
    data: dasCTagMap,
    isFetching: isFetchingDasCTags,
    hasNextPage: hasNextPagDasCTag,
    fetchNextPage: fetchNextPageDasCTag,
  } = useProjectDasCTagMapWithPages({
    projectId,
    queryKey: ['get-project-of-dasCTag', projectId],
    queryOptions: {
      enabled: resourceType === 'worker',
    },
    onProgress: (localProgress) => {
      setProgress(localProgress);
    },
  });

  useEffect(() => {
    if (!isFetchingDasLoops && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingDasLoops]);

  useEffect(() => {
    if (!isFetchingDasCTags && hasNextPagDasCTag) {
      fetchNextPageDasCTag();
    }
  }, [hasNextPagDasCTag, isFetchingDasCTags]);

  const { data: dasconcreteMap, isLoading: isLoadingDasconcrete } =
    useProjectDasConcreteMap({
      projectId,
      queryKey: ['get-project-of-dasconcretes', projectId],
      enabled: resourceType === 'structure',
      refetchOnWindowFocus: false,
    });
  const { data: dascasMap, isLoading: isDascasMapLoading } =
    useIdDascasMap<Dascas>({
      projectId,
      queryKey: ['get-project-of-dascases', projectId],
      indexBy: 'dasId',
      enabled: resourceType === 'crane',
      type: 'dascas',
      refetchOnWindowFocus: false,
    });

  const cards = data;

  const handleAddCard = () => {
    handleDasIDWillAssign([...cards, INIT_WORKER_CARD]);
  };

  const onChangeCard = (index: number, datas: DasIdWillAssign | undefined) => {
    if (datas) {
      const newDatas = [...cards];
      newDatas[index] = datas;
      handleDasIDWillAssign(newDatas);
    } else {
      handleDasIDWillAssign([INIT_WORKER_CARD]);
    }
  };

  const onDeleteCard = (index: number) => {
    const newcards = [...cards];
    newcards.splice(index, 1);
    handleDasIDWillAssign(newcards);
  };

  const projectOfDevices = useMemo<Device[]>(() => {
    if (resourceType === 'worker') {
      const dasloops = Object.values(dasloopMap) as Device[];
      const dasCTags = Object.values(dasCTagMap) as Device[];
      return dasloops.concat(dasCTags);
    } else if (resourceType === 'crane') {
      return Object.values(dascasMap) as Device[];
    } else if (resourceType === 'structure') {
      return Object.values(dasconcreteMap) as Device[];
    } else {
      return [];
    }
  }, [resourceType, dasloopMap, dascasMap, dasconcreteMap]);

  const isLoading = useMemo(() => {
    switch (resourceType) {
      case 'worker':
        return false;
      case 'structure':
        return isLoadingDasconcrete;
      case 'crane':
        return isDascasMapLoading;
      default:
        return false;
    }
  }, [resourceType, isLoadingDasconcrete, isDascasMapLoading]);

  const items = cards.map((item, i) => (
    <ConnectedDeviceCard
      isLoading={isLoading}
      progress={progress}
      key={`ConnectedDeviceCard-${item.id}-${i}`}
      devices={projectOfDevices}
      index={i}
      connectedDevice={item}
      onChange={onChangeCard}
      onDeleteCard={onDeleteCard}
      type={resourceType}
      location={location}
    />
  ));

  return (
    <Container>
      <ContainerContent>
        <ContentTitle>{t('connected-device')}</ContentTitle>
        <Button
          startIcon={<AddSvgIcon sx={{ width: '32px', height: '32px' }} />}
          onClick={handleAddCard}
          data-cy="btn-add-device"
        >
          {t(
            'page.equipment-management.dialog.create-equipment.connected-device.title-button',
          )}
        </Button>
        <Content>{items}</Content>
      </ContainerContent>
    </Container>
  );
};

export default ConnectedDeviceContent;
